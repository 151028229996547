exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-architecture-js": () => import("./../../../src/templates/architecture.js" /* webpackChunkName: "component---src-templates-architecture-js" */),
  "component---src-templates-conatct-js": () => import("./../../../src/templates/conatct.js" /* webpackChunkName: "component---src-templates-conatct-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-functional-space-js": () => import("./../../../src/templates/functionalSpace.js" /* webpackChunkName: "component---src-templates-functional-space-js" */),
  "component---src-templates-library-space-js": () => import("./../../../src/templates/librarySpace.js" /* webpackChunkName: "component---src-templates-library-space-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-outdoor-space-js": () => import("./../../../src/templates/outdoorSpace.js" /* webpackChunkName: "component---src-templates-outdoor-space-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-the-project-js": () => import("./../../../src/templates/theProject.js" /* webpackChunkName: "component---src-templates-the-project-js" */)
}

